//import http from './asyncAxiosApi';
import http from '@/utils/http';

const apiList = {
  // 活动模板
  getMailList: `/account/mailTemp/page`, // GET 邮件消息列表
  addMailItem: `/account/mailTemp`, // POST 新增邮件消息
  delMailItem: `/account/mailTemp`, // DELETE 删除邮件消息
  editMailItem: `/account/mailTemp`, // PUT 删除邮件消息
  getMessageTplList: `/account/msgTemp/page`, // GET 消息模板列表
  getMessageItem: id => `/account/msgTemp/${id}`, // GET 获取消息模板详情
  editMessageItem: `/account/msgTemp`, // PUT 修改消息模板
  addMessageItem: `/account/msgTemp`, // POST 新增消息模板
  delMessageItem: `/account/msgTemp`, // PUT 新增消息模板

  // 推送计划
  getPushPlanList: `/account/pushPlan/page`, // GET 推送计划列表
  delPushPlanItem: `/account/pushPlan`, // DELETE 删除推送计划
  getGroupList: `/account/memberGroup/list`, // GET 查询群组列表
  getGroupItem: `/account/memberGroupInfo/listPage`, // GET 查询群组成员
  getMailTempList: `/account/mailTemp/list`, // GET 查询邮件消息模板列表
  saveOrUpdate: `/account/pushPlan/saveOrUpdate`, // POST 新增修改推送
  sendPushPlan: `/account/pushPlan/send`, // GET 发送计划
  getPushPlanDetail: id => `/account/pushPlan/${id}`, // GET 查询推送计划详情
  getPushPlanTargets: `/account/pushPlanMember/getByPushId`, // GET 查询推送目标集合
  getTplTypeList: `/authority/dictionaryItem/codes`, // GET 查询推送目标集合
};

export default {
  pushPageUrl: '/account/pushPlan/page',
  emailTplUrl: '/account/mailTemp/page',
  //新增推送计划
  listPage: 'account/memberGroupInfo/listPage', //智能分群列表
  getPushPlanList(code, params) {
    return http.get('/account/pushPlan/page', { params, code });
  },
  getPushPlanDetail(code, id) {
    return http.get(`/account/pushPlan/${id}`, { code });
  },
  getPushPlanTargets(code, params) {
    return http.get('/account/pushPlanMember/getByPushId', { params, code });
  },
  sendPushPlan(code, params) {
    return http.get('/account/pushPlan/send', { params, code });
  },
  saveOrUpdate(code, data) {
    return http.post('/account/pushPlan/saveOrUpdate', data, { code });
  },
  delPushPlanItem(code, params) {
    return http.delete('/account/pushPlan', { params, code });
  },
  getMailTempList(code, params) {
    return http.get('/account/mailTemp/list', { params, code });
  },
  getMessageTplList(code, params) {
    return http.get('/account/msgTemp/page', { params, code });
  },
  getMessageItem(code, id) {
    return http.get(`/account/msgTemp/${id}`, { code });
  },
  addMailItem(code, data) {
    return http.post('/account/mailTemp', data, { code });
  },
  editMailItem(code, data) {
    return http.put('/account/mailTemp', data, { code });
  },
  delMailItem(code, params) {
    return http.delete('/account/mailTemp', { params, code });
  },
  delMessageItem(code, params) {
    return http.delete('/account/msgTemp', { params, code });
  },
  getTplTypeList(code) {
    return http.get('/authority/dictionaryItem/codes', {
      params: { codes: 'MAILTEMPTYPE' },
      code,
    });
  },

  getMailList(data) {
    return http({
      method: 'GET',
      url: apiList.getMailList,
      data: data || {},
    });
  },

  editMessageItem(data = {}) {
    return http({
      method: 'PUT',
      url: apiList.editMessageItem,
      data,
    });
  },

  addMessageItem(data = {}) {
    return http({
      method: 'POST',
      url: apiList.addMessageItem,
      data,
    });
  },

  getGroupList(data = {}) {
    return http({
      method: 'GET',
      url: apiList.getGroupList,
      data,
    });
  },

  getGroupItem(data = {}) {
    return http({
      method: 'GET',
      url: apiList.getGroupItem,
      data,
    });
  },
};
